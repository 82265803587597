import styled from "styled-components";

export const MainDiv = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  padding: 55px;
  max-width: 900px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 0px #44444461;
  display: block;
  position: absolute;
  margin-top: -190px;
  background-color: white;
  @media (max-width: 1440px) {
    margin-top: -190px;
  }
  @media (max-width: 1024px) {
    width: 70%;
    padding: 2% 4%;
    font-size: 14px;
    margin-top: -90px;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 4% 5%;
    font-size: 14px;
    margin-top: -65px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`;

export const Image = styled.img`
  max-width: 350px;
  @media (max-width: 1440px) {
    max-width: 280px;
  }
  @media (max-width: 768px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    max-width: 160px;
  }
`;

export const Paragraph = styled.div`
  font-size: 18px;
  opacity: 0.5;
  text-align: center;
  line-height: 30px;
  @media (max-width: 1024px) {
    font-size: 16px;
    opacity: 0.9;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    opacity: 0.9;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
    line-height: 22px;
  }
`;
